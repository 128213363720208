import React from "react"
import Spinner from "components/Spinner"
import loadable from "@loadable/component"

const LoadableTerms = loadable(() => import("components/TermsPage"), {
  fallback: <Spinner />,
})

const Terms = () => {
  return <LoadableTerms />
}

export default Terms
